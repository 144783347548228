export const namespaces = {
    navbar:"navbar",
    legend: "legend",
    about: "about",
    funds: "funds",
    community: "community",
    facts: "facts",
    roadMap: "roadmap",
    team:"team",
    faq:"faq",
    footer:"footer",
    socialMedia:"socialMedia",
    mint: "mint",
    aboutRF: "aboutRF",
    utility: "utility",
    advisor: "advisor",
    presentation : "presentation",
    cardsPresentation: "cardsPresentation",
    partners: "partners"
};

export const languages = {
    ro: "ro",
    en: "en",
};
import SocialMediaIcons from "../socialMedia/SocialMediaIcons";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

function FooterSocial() {
    const {t} = useTranslation(namespaces.footer)

    return(
        <div className={"footerSocialLayout"}>
            <h2 className={"footerContactTitleStyle"}>{t("socialMedia.title",{ns:namespaces.footer})}</h2>
            <ul className={"footerSocialIconsLayout"}>
                <SocialMediaIcons/>
            </ul>
        </div>
    )
}

export default FooterSocial
import {FaqModel} from "../../models/Faq.model";
import {useState} from "react";

const FaqElements = (faqElements: FaqModel) => {
	const [show, setshow] = useState(false);

	const goToFaq = () => {
		window.location.replace("/faq")
	}

	const answer =
		<div className={"faqElementAnswerLayout animate__animated animate__fadeIn"}>
			<div className={"faqElementAnswerStyle"}>{faqElements.answer}</div>
			<div className={"faqElementAnswerLayout faqElementReadMoreStyle"} onClick={goToFaq}>
				<div>Read More</div>
				<div className={"faqElementsArrowLayout"}>
					<img src={"arrows/arrowRightOrange.png"} className={"faqElementsArrowRightLayout"}/>
				</div>
			</div>
		</div>

	const arrow = () => {
		if (show) {
			return (
				<div>
					<img src={"arrows/arrowUp.png"} className={"faqElementsArrowRightLayout"} alt={""}/>
				</div>
			)
		} else {
			return (
				<div>
					<img src={"arrows/arrowDown.png"} className={"faqElementsArrowRightLayout"} alt={""}/>
				</div>
			)
		}

	}

	return (
		<div className={"faqElementsLayout"}>
			<div className={"faqElementQuestionLayout"}>
				<div className={"faqElementQuestionStyle"}>
					{faqElements.question}
				</div>
				<div className={"faqElementsOpenLayout"} onClick={() => {
					setshow(!show)
				}}>
					{arrow()}
				</div>
			</div>
				{show ? answer : ""}

		</div>
	)
}

export default FaqElements
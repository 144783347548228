import React from 'react';
import useBreakpoint from "../../genericBreakpoint";
import DropdownNav from "./DropdownNav";
import {InlineNav} from "./InlineNav";

const Navbar = () => {
    const breakpoint = useBreakpoint();

    switch (breakpoint) {
        case("desktop"):
            return (
                <div className={"navbarRising"}>
                    <InlineNav/>
                </div>
            );
        case("phone"):
            return (
                <div className={"navbarRising"}>
                    <DropdownNav/>
                </div>
            );
        default:
            return <></>
    }

}

export default Navbar;

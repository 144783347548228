import useBreakpoint from "../../genericBreakpoint";
import {useState} from "react";
import {namespaces} from "../../i18n/i18n.constants";
import {useTranslation} from "react-i18next";


const OurLegend = () => {
	const breakpoint = useBreakpoint();
	const [readMore, setReadMore] = useState(false);
	const {t} = useTranslation(namespaces.legend);
	const legends:Array<string> = t("legends", {ns: namespaces.legend, returnObjects: true});

	const readLess =
		<a href = {"#legend"}>
			<div className={"legendReadFlexLayout"}>
					<div className = "legendReadLessStyle">Read Less</div>
					<div className={"bounce-up"}>
						<img src={"arrows/arrowUpOrange.png"} className={"legendReadIconLayout"}/>
					</div>
			</div>
		</a>

	const readMoreText =
		<div className={"legendReadFlexLayout"}>
			<div>Read More</div>
			<div className={"dropDownNavBounceAnimation"}>
				<img src={"arrows/arrowDownOrange.png"} className={"legendReadIconLayout"}/>
			</div>
		</div>

	const readMoreAction =
		<div className={"outLegendReadMoreLayout"}>
			<div className={"readMoreButton"} onClick={() => setReadMore(!readMore)}>
				{readMore
					? readLess
					: readMoreText}
			</div>
		</div>

	const legendDesktop =
		<div className={"ourLegendFlexLayout"}>
			{
				legends.map((element, key) => {
					return(
						<div key={key} className={"ourLegendFlexElementLayout"}>
							<p className={"ourLegendContentStyle"}>{element}</p>
						</div>
					)
				})
			}
		</div>

	const legendMobile =
		<div className={"ourLegendFlexLayout"}>
			{readMore
				? legends.map((element) => {
					return(
						<div className={"ourLegendFlexElementLayout"}>
							<p className={"ourLegendContentStyle"}>{element}</p>
						</div>
					)
				})

				: <div className={"ourLegendFlexElementLayout"}>
					<p className={"ourLegendContentStyle"}>{legends[0]}</p>
				</div>
			}
		</div>

	switch (breakpoint) {
		case "desktop":
			return (
				<div className={"ourLegendLayout"}>
					{legendDesktop}
				</div>
			)
		case "phone":
			return (
				<div className={"ourLegendLayout"}>
					{legendMobile}
					{readMoreAction}
				</div>

			)
		default:
			return <></>
	}

}

export default OurLegend
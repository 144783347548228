import FaqElements from "./FaqElements";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

const Faq = () => {
	const {t} = useTranslation(namespaces.faq)
	const faqs: Array<any> = t("faqShort",{ns:namespaces.faq, returnObjects:true})
	return (
		<div>
			{
				faqs.map((e, index) => {
					const f = "faqElements" + index
					return (
						<FaqElements question={e["question"]} answer={e["answer"]} key={f}/>
					)
				})
			}
		</div>
	)
}

export default Faq;
import {routes} from "../routes";
import {AuthenticatedRoutesWrapper} from "@elrondnetwork/dapp-core";
import {useLocation} from "react-router-dom";

export const Layout = ({ children }: { children: React.ReactNode }) => {
	const {search} = useLocation()

	return (
		<AuthenticatedRoutesWrapper routes={routes} unlockRoute={`/unlock${search}`}>
			{children}
		</AuthenticatedRoutesWrapper>
	)
}
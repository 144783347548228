import GridElement from "./GridElement";
import {useTranslation} from "react-i18next";

type Props = {
    dataName:string
    namespace:string
}

const PresentationGrid = (props: Props) =>   {
    const {t} = useTranslation(props.namespace);
    const data: Array<any> = t(props.dataName, {ns:props.namespace, returnObjects: true})

    return (
        <div className={"presentationGridLayout"}>
            {data.map((e, key) => {
                const k:string = "gridElement" + key
                return (
                    <GridElement key={k} data={e}/>
                )
            })}
        </div>

    )
}

export default PresentationGrid
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

const  FooterBuy = () => {
	const {t} = useTranslation(namespaces.footer)
	const elements: Array<any> = t("whereToBuy.elements", {ns: namespaces.footer, returnObjects: true})

	return (
		<div className={"footerBuyLayout"}>
			<h2 className={"footerContactTitleStyle"}>{t("whereToBuy.title", {ns: namespaces.footer})}</h2>
			<ul className={"footerSocialIconsLayout"}>
				{
					elements.map((e, index) => {
						const k = "footer" + index
						return (
							<li key={k} className={"footerBuyLinkStyle"}>
								<a href={"#navbar"} className={"footerContactSubtitleStyle"}>{e["name"]}</a>
							</li>
						)
					})
				}

			</ul>
		</div>
	)
}

export default FooterBuy
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './style/variables/_colors.sass'
import './style/_generic.sass'
import "./style/_stars.sass"
import "./i18n/i18n";
import "./index.css"
import {Loading} from "./components/loading/Loading";

ReactDOM.render(
    <React.Suspense fallback={<Loading/>}>
        <div>
            <div style={{position: "fixed", left: 0, top: 0, zIndex: -5}}>
                <div id='stars'/>
                <div id='stars2'/>
                <div id='stars3'/>
            </div>
            <App/>
        </div>
    </React.Suspense>,
    document.getElementById('root')
);

reportWebVitals();

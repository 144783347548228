import React from 'react';

import './style/layout/layout.sass'
import "./style/_stars.sass"
import "./style/components/genericSlide.sass"
import "./style/components/howDoWeMeet.sass"
import "./style/components/footer.sass"
import "./style/variables/breakpoints.sass"
import './style/variables/_colors.sass'
import './style/variables/_fonts.sass'
import "./style/components/loading.sass"
import "./style/components/mintNft.sass"
import "./style/components/advisor.sass"
import './style/components/navbar.sass'
import "./style/components/presentation.sass"
import "./style/components/slide.sass"
import "./style/components/team.sass"
import "./style/components/ourLegend.sass"
import "./style/components/card.sass"
import "./style/components/why.sass"
import "./style/components/community.sass"
import "./style/components/faq.sass"
import "./style/components/modal.sass"
import "./style/components/socialMedia.sass"
import "./style/components/cardsPresentation.sass"
import "./style/components/shineAnimation.css"
import "./style/_theme.sass"
import "./style/components/slideShow.sass";
import "./style/components/utility.sass"
import "./style/components/carouselGif.sass"
import "./style/components/comingSoon.sass"
import "./style/components/completeMint.sass"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'animate.css';
import "bootstrap/scss/bootstrap.scss";

import {BrowserRouter} from "react-router-dom";
import {Route} from "react-router-dom";
import {RisingFauna} from "./RisingFauna";

import {Switch} from "react-router-dom";
import Ledger from "./components/maiarLogin/Ledger";
import WalletConnect from "./components/maiarLogin/WalletConnect";
import {FaqFull} from "./components/faq/FaqFull";
import {Counter} from "./components/counter/Counter";
import ComingSoon from "./components/comingSoon/ComingSoon";
import CompleteMint from "./components/completeMint/CompleteMint";
import {DappProvider, DappUI} from '@elrondnetwork/dapp-core';
import '@elrondnetwork/dapp-core/build/index.css';
import {Layout} from "./components/Layout";


//TODO 4 Remove unused sass classes
//TODO 5 Remove fonts from classes and generic ones

// TODO - Transform images into svg
// TODO - Migrate to next
// TODO - If wanted, newsletter can be added
// TODO - Replace prime react carousel with one made out of css
// TODO - Replace stars animation with canvas, if possible
/*
* Usefull links
* https://jsfiddle.net/loktar/UdyN6/ - starting point for canvas
* https://codepen.io/rokobuljan/pen/XXzqKQ - carusel made out of css ( Replace line 22 with : transition: 0.5s all ease-in-out;)
* https://pagespeed.web.dev/?utm_source=psi&utm_medium=redirect - better website for testing performance
* https://mailchimp.com/pricing/marketing/ - third party mail provider
* */

const App = () => {
	const environment = 'mainnet';
	const {
		TransactionsToastList,
		SignTransactionsModals,
		NotificationModal,
		DappCorePages: { UnlockPage }
	} = DappUI;
	return (
		<>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<DappProvider
					environment={environment}
					customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
					completedTransactionsDelay={200}>
					<Layout>
						<TransactionsToastList/>
						<NotificationModal />
						<SignTransactionsModals/>
						<Switch>
							<Route path="/unlock">
								<UnlockPage loginRoute={"/dashboard"} />
							</Route>
							<Route path="/ledger">
								<Ledger/>
							</Route>
							<Route path="/walletconnect">
								<WalletConnect/>
							</Route>
							<Route path = "/faq">
								<FaqFull/>
							</Route>
							<Route path = "/counter">
								<Counter/>
							</Route>
							<Route path={"/completedMint"}>
								<CompleteMint/>
							</Route>
							<Route path = "/">
								<RisingFauna/>
							</Route>

						</Switch>
					</Layout>
				</DappProvider>

			</BrowserRouter>
		</>
	)
}

export default App;
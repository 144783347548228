import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {useState} from "react";

export type Utility = {
	id: string,
	icon: string
	title: string,
	text: string,
	points?: Array<string>
}

function UtilityRF() {
	const {t} = useTranslation(namespaces.utility);
	const utilities: Array<Utility> = t("utilities", {ns: namespaces.utility, returnObjects: true})
	const generateId = () => {
		let ids: { [id: string]: boolean } = {};
		utilities.map((elements, index): void => {
			ids["id" + index] = false;
			elements.id = "id" + index
		})
		return ids;
	}

	const [utility, setUtility] = useState<{ [id: string]: boolean }>(generateId());

	const handleclick = (id: string) => {
		utilities.map((elements, index): void => {
			setUtility((prevState) => {
				return {
					...prevState, ["id" + index]: false
				}
			})
		})
		setUtility((prevState) => {
			return (
				{
					...prevState, [id]: !utility[id]
				}
			)
		})
	}


	const utilityElements = <>{utilities.map((elements, index) => {
		const id: string = "id" + index
		const key: string = "utilityRef" + index
		{
			const utilityElementPopup = <>{utility[id] &&

                <div className={"utilityInfoMainLayout animate__animated animate__fadeIn"}>
                    <div className={"utilityTriangleLayout"}/>
                    <div className={"utilityInfoLayout"}>
                        <div className={"utilityInnerTitleStyle utilityInnerTitleLayout"}>{elements.title}</div>
                        <div className={"utilityInnerTextStyle utilityInnerTextLayout"}>{elements.text}</div>
                        <div className={"utilityListLayout"}>
                            <ul className={"utilityListStyle"}>
								{
									elements.points?.map((points, index) => {
										const k = "utilityElem" + index
										return (<li className={"utilityListElementStyle"} key={k}>{points}</li>)
									})
								}
                            </ul>
                        </div>
                    </div>
                </div>
			}</>;
			return (
				<div key={key} className={"utilityMainFlexLayout"}>
					<div className={"utilityFlexLayout"} onClick={() => handleclick(id)}>
						<div className={"utilityIconContainerLayout"}>
							<img src={elements.icon} className={"utilityIconStyle utilityIconLayout"} alt={""}/>
						</div>
						<div className={"utilityTitleStyle utilityTitleLayout"}>{elements.title}</div>
					</div>
					{utilityElementPopup}
				</div>
			)
		}
	})}</>;
	return (
		<div style={{display: "block"}}>
			<div className={"utilityMainLayout"}>
				{utilityElements}
			</div>
		</div>
	)
}

export default UtilityRF
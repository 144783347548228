import {Advisor} from "./Advisor";
import {useTranslation} from "react-i18next";

type Props = {
	dataName: string
	namespace: string
}

export const Advisors = (props: Props) => {
	const {t} = useTranslation(props.namespace)
	const advisorsElements: Array<any> = t(props.dataName, {ns: props.namespace, returnObjects: true})
	return (
		<>
			{advisorsElements.map((advisor, key) => {
				const k = "advisors" + key
				return (
					<div key={k} className={"advisorsLayout"}>
						<Advisor
							subName={advisor["subName"]}
							contact={advisor["contact"]}
							name={advisor["name"]}
							image={advisor["image"]}/>

					</div>
				)
			})}
		</>
	)

}

import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

export function FaqFull() {
    const goBack = () =>{
        window.location.replace("/")
    }
    const {t} = useTranslation(namespaces.faq);
    const faqs:Array<any> = t("faqFull", {ns:namespaces.faq, returnObjects:true});
    return(
        <div style={{marginTop: "3%"}}>
            <div className = "faq">
                <div className={"teamTitleLayout"}>
                    <h1 className={"teamTitleHLayout teamTitleHStyle"}>{t("title",{ns:namespaces.faq})}</h1>
                </div>
                {
                    faqs.map((e,index) => {
                        return(
                        <div className={"faqElementsLayout"}>
                            <div className={"faqElementQuestionLayout"}>
                                <div className = {"faqElementQuestionStyle"}>
                                    {e["question"]}
                                </div>

                            </div>

                                <div className={"faqElementAnswerLayout"}>{e["answer"]}</div>

                        </div>)
                    })
                }
            </div>
            <div className={"faqBackButton"}>
                <div onClick={goBack} className={"btn btn-primary faqBackButtonClick"}>Back</div>
            </div>
        </div>
    )
}
import Slide from "./components/slide/Slide";
import OurLegend from "./components/ourLegend/OurLegend";
import About from "./components/about/About";
import PresentationGrid from "./components/presentationgrid/PresentationGrid";
import Community from "./components/community/Community";
import Team from "./components/team/Team";
import Footer from "./components/footer/Footer";
import React, {useEffect} from "react";
import Faq from "./components/faq/Faq";
import {namespaces} from "./i18n/i18n.constants";
import {useTranslation} from "react-i18next";
import {AboutRF} from "./components/aboutRF/AboutRF";
import Presentation from "./components/presentation/Presentation";
import Navbar from "./components/navbar/Navbar";
import {CardsPresentation} from "./components/cards/CardsPresentation";
import {Advisors} from "./components/advisors/Advisors";
import Carousel from "./components/carouselslide/Carousel";
import UtilityRF from "./components/utility/Utility";
import MintNft from "./components/mintNft/MintNft";
import {useGetLoginInfo} from "@elrondnetwork/dapp-core";

export const RisingFauna = () => {
    const {t} = useTranslation([namespaces.legend,namespaces.about,namespaces.funds, namespaces.community, namespaces.facts, namespaces.roadMap,namespaces.aboutRF, namespaces.advisor, namespaces.partners,  namespaces.utility])
	const { isLoggedIn } = useGetLoginInfo();
	useEffect(() => {
		if(!isLoggedIn){
			localStorage.clear()
		}
	}, [isLoggedIn])

    return(
        <>
	        <Navbar/>
	        <div className={"generic-layout"}>
                <div className={"generic-layout"}>
	                <Presentation/>
                    <div>
                        <Slide
                            idModel={"slide1"}
                            id={"story"}
                            lineHeight={"100px"}
                            lineColor={"#d86938"}
                            rightModel={{
                                "title": t("title",{ns: namespaces.aboutRF}),
                                "elementToRender": <AboutRF/>,
                                "subtitle": ""
                            }}/>
                    </div>
                    <div>
                        <Slide
                            idModel={"legend"}
                            id={"legend"}
                            lineHeight={"100px"}
                            lineColor={"white"}
                            rightModel={{
                                "title": t("title",{ns: namespaces.legend}),
                                "elementToRender": <OurLegend/>,
                                "subtitle": ""
                            }}/>
                    </div>
                    <div >
                        <Slide
                            idModel={"about"}
                            id={"about"}
                            lineHeight={"100px"}
                            lineColor={"#d86938"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.about}),
                                "elementToRender": <About/>,
                                "subtitle": ""
                            }}/>
                    </div>
                    <div id={"utilities"}>
                        <Slide
                            idModel={"utilities"}
                            id={"utilities"}
                            lineHeight={"400px"}
                            lineColor={"#F8F5FA"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.utility}),
                                "elementToRender": <UtilityRF/>,
                                "subtitle": t("subtitle", {ns: namespaces.utility})
                            }}/>
                    </div>
                    <div>
                        <Slide
                            idModel={"community"}
                            id={"community"}
                            lineHeight={"100px"}
                            lineColor={"#d86938"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.community}),
                                "elementToRender": <Community/>,
                                "subtitle": ""
                            }}/>
                    </div>
                    <div>
                        <Slide
                            idModel={"facts"}
                            id={"facts"}
                            lineHeight={"100px"}
                            lineColor={"#F8F5FA"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.facts}),
                                "elementToRender": <Carousel/>,
                                "subtitle": ""
                            }}/>
                    </div>
                    <div>
                        <Slide
                            idModel={"funds"}
                            id={"funds"}
                            lineHeight={"100px"}
                            lineColor={"#d86938"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.funds}),
                                "elementToRender": <PresentationGrid dataName={"funds"} namespace={namespaces.funds}/>,
                                "subtitle": t("subtitle", {ns: namespaces.funds}),
                                "secondSubtitle": t("secondSubtitle", {ns: namespaces.funds})
                            }}/>
                    </div>
                    <div>
                        <Slide
                            idModel={"roadmap"}
                            id={"roadmap"}
                            lineHeight={"100px"}
                            lineColor={"#F8F5FA"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.roadMap}),
                                "elementToRender": <PresentationGrid dataName={"roadmap"} namespace={namespaces.roadMap}/>,
                                "subtitle": ""
                            }}/>
                    </div>
					<div id={"team"}>
						<Slide
							idModel={"team"}
							id={"team"}
							lineHeight={"100px"}
							aditionalPadding={"40px"}
							rightModel={{
								"title": "Meet the creatives",
								"elementToRender": <Team/>,
								"subtitle": "Presenting the team members",
                                "secondSubtitle": ""
							}}/>
					</div>
                    <div>
                        <Slide
                            idModel={"advisors"}
                            id={"advisors"}
                            lineHeight={"100px"}
                            lineColor={"#F8F5FA"}
                            rightModel={{
                                "title": t("title", {ns: namespaces.advisor}),
                                "elementToRender": <Advisors dataName={"advisors"} namespace={namespaces.advisor}/>,
                                "subtitle": t("subtitle", {ns: namespaces.advisor})
                            }}/>
                    </div>
	                <div>
		                <Slide
			                idModel={"partners"}
			                id={"partners"}
			                lineHeight={"100px"}
			                lineColor={"#F8F5FA"}
			                rightModel={{
				                "title": t("title", {ns: namespaces.partners}),
				                "elementToRender": <Advisors dataName={"partners"} namespace={namespaces.partners}/>,
				                "subtitle": t("subtitle", {ns: namespaces.partners})
			                }}/>
	                </div>
					<div id={"faq"}>
						<Slide
							idModel={"faq"}
							id={"faq"}
							lineHeight={"400px"}
							rightModel={{
								"title": "FAQ",
								"elementToRender": <Faq/>,
								"subtitle": ""
							}}/>
					</div>
					<Footer/>
				</div>
			</div>
		</>
	)
}

import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

function FooterContact() {
    const {t} = useTranslation(namespaces.footer)

    return(
        <div className={"footerContactLayout"}>
            <h2 className={"footerContactTitleStyle"}>{t("contact.title", {ns: namespaces.footer})}</h2>
            <p className={"footerContactSubtitleStyle"}>{t("contact.email", {ns: namespaces.footer})}</p>
        </div>
    )
}

export default FooterContact
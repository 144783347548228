import {namespaces} from "../../i18n/i18n.constants";
import {useTranslation} from "react-i18next";

export function AboutRF(){
    const{t} = useTranslation(namespaces.aboutRF);

    return(
        <div>
            <div className={"ourLegendLayout"}>
                <div className={"gridElementLayout"}>
                    <p className={"ourLegendContentStyle"}>{t("text", { ns: namespaces.aboutRF })}</p>
                </div>
            </div>
        </div>
    )
}
export const stringToHex = (str: string) => {
    if (str) {
        const arr1 = [];
        for (let n = 0, l = str.length; n < l; n++) {
            const hex = Number(str.charCodeAt(n)).toString(16);
            arr1.push(hex);
        }
        return arr1.join('');
    }
    return '';
};

export const hexToString = (strVal: string) => {
    if (strVal) {
        const hex = strVal.toString();
        let str = '';
        for (let n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }
    return '';
};

export const shortenWalletAddress = (address: string, charsAmount = 6) => {
    const firstPart = address.substring(0, charsAmount);
    const lastPart = address.substring(
        address.length - charsAmount,
        address.length
    );
    return `${firstPart}...${lastPart}`;
};

export const generateHelpArray = (numberOfElements: number) => {
    const array = [];
    for(let i = 0; i < numberOfElements;i++){
        array.push(i);
    }
    return array;
}


export default function decimalToHexa(value:string) {
    const length = value.length;
    return "0".repeat(4-length) + value
}
import React from "react"
import {RenderModel} from "../../models/Slide.data";

type Props = {
	lineColor?: string,
	lineHeight?: string
	id: string
	rightModel: RenderModel
	idModel: string
	aditionalPadding?: string
}

const Slide = (props: Props) => {
	const {lineColor, lineHeight, aditionalPadding} = props
	const {title, subtitle, secondSubtitle} = props.rightModel

	const text =
		<div className={"slideTextLayout"}>
			<h1 className={"container-title-h"}>{title}</h1>
			<p className={"container-subtitle containerSubtitleOrangeStyle"}>{subtitle}</p>
			<p className={"container-subtitle"}>{secondSubtitle}</p>
		</div>

	const toRender =
		<div className={"gridElementLayout"}>
			{props.rightModel.elementToRender}
		</div>

	const line =
		(lineColor && (<div className={"line"} style={{background: `${lineColor}`, borderColor: `${lineColor}`}}/>))
	return (
		<div className={"slideLayout"} style={lineColor ? {marginTop: "0"} : {paddingTop: `${aditionalPadding}`}}>
			{line}
			<div className={"slideContentLayout"}
			     id={props.idModel}
			     style={{
				     minHeight: lineHeight
			     }}>
				<div className={"slideRenderElementLayout"}
				     style={lineColor ? {
					     marginBottom: "5%", paddingTop: "3%"
				     } : {paddingTop: "0"}}>
					{text}
					{toRender}
				</div>
			</div>
			{line}
		</div>
	)
}

export default Slide;
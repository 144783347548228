import useBreakpoint from "../../genericBreakpoint";
import {carouselElement} from "./utils";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import Slider from "react-slick";

const Carousel = () => {
	const breakPoint = useBreakpoint();
	const {t} = useTranslation(namespaces.facts)
	const elements: Array<any> = breakPoint === "desktop"
		? t("factDesktop", {ns: namespaces.facts, returnObjects: true}) :
		t("factMobile", {ns: namespaces.facts, returnObjects: true}) ;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 6000,
		className: 'carouselSliderLayout',
		responsive: [
			{
				breakpoint: 990,
				settings: {
					arrows: false,

				}
			}
		]
    };
	return (
		<div className={"carouselMainLayout"}>
			<Slider {...settings}>
						{elements?.map((e, index) => {
							return carouselElement(e, index)
						})
						}

			</Slider>
		</div>
	)
}


export default Carousel
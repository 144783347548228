import useBreakpoint from "../../genericBreakpoint";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

const Community = () => {
	const {t} = useTranslation(namespaces.community);
	const breakpoint = useBreakpoint();

	const communityTitle =
		<div className={"communityLinkLayout"}>
			<a className={"communityLinkAnchorStyle"}
			   href={t("link",{ns: namespaces.community})}>
				{t("button",{ns:namespaces.community})}
			</a>
		</div>

	const communityImage =
		<div className={"communityImageLayout"}>
			<img className={"communityLogoStyle"}
			     src={breakpoint === "desktop" ? t("communityDesktop",{ns:namespaces.community}) : t("communityMobile",{ns:namespaces.community})}
				 alt = {t("alt",{ns:namespaces.community})}/>
		</div>

	return (
		<>
			{communityTitle}
			{communityImage}
		</>
	)
}

export default Community
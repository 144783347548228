import {RisingFauna} from "./RisingFauna";
import {Counter} from "./components/counter/Counter";

export const routes = [
	{
		path: '/counter',
		component: Counter,
		authenticatedRoute: true,
	},{
		path:"/",
		component: RisingFauna,
		authenticatedRoute: false
	}
];

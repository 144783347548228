import React from "react";
import useBreakpoint from "../../genericBreakpoint";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {useGetAccountInfo, useGetLoginInfo} from "@elrondnetwork/dapp-core";

const Presentation = () => {
	const breakpoint = useBreakpoint()
	const {t} = useTranslation(namespaces.presentation);
	const { isLoggedIn } = useGetLoginInfo();
	const {address} = useGetAccountInfo()
	const left = (
		<div className={"presentationDeskGifLayout"}>
			<img src={"left.gif"} className={"presentationGifLayout"} alt={""}/>
		</div>
	)

	const right = (
		<div className={"presentationDeskGifLayout"}>
			<img src={"right.gif"} className={"presentationGifLayout"} alt={""}/>
		</div>
	)

	const logoDesktop = (
		<div className={"presentationLogoDesktopLayout"}>
			<img src={"logo-r1.png"} alt={""} className={"presentationLogoStyle"}/>
		</div>
	)

	const middle = (
		<img src={"mobileGif.gif"} className={"presentationGifLayout"} alt={""}/>
	)

	const logoMobile = (
		<div className={"presentationLogoMobileLayout"}>
			<img src={"logo-r.png"} alt={""} className={"presentationLogoStyle"}/>
		</div>
	)

	const mintButton = (
		<div className={"presentationButtonContainerLayout"}>
			<div className="presentationButtonLayout">
				<button className="presentationButtonStyle presentationButtonAnimation"
				        onClick={() => window.location.replace("/counter")}>MINT
				</button>
			</div>
		</div>
	)

	const info = (
		<div className={"presentationLoggedInLayout"}>
			<div className={"presentationContainerLayout"}>
				<div className={"presentationYourAddressLayout"}>
					<div className={"presentationYourAddressStyle"}>
						{t("address", {ns: namespaces.presentation})}
					</div>
					<div className={"presentationAddressStyle"}>
						<div className={"presentationInfoLayout"}>
							<img src={"accept.png"} alt={""}
						     className={"presentationAcceptIconLayout"}/>
							<div style={{paddingTop: "2px"}}>
								{isLoggedIn && address.toString().substring(0, 4) + "..." + address.toString().substring(address.length - 5, address.length)}
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	)

	const annoncement = (
		<div className = "delayReason">
			{t("annoncement", {ns: namespaces.presentation})}
		</div>
	)

	const content = () => {
		switch (breakpoint) {
			case("phone"):
				return (
					<div>
						{logoMobile}
						{annoncement}
						{middle}
						{isLoggedIn && info}
						{mintButton}
					</div>
				);
			case("desktop"):
				return (
					<>
						{left}
						<div>
							{logoDesktop}
							{annoncement}
							{isLoggedIn && info}
							{mintButton}
						</div>
						{right}
					</>
				);
			default:

		}
	}

	return (
		<div className={"presentationLayout"}>
			<div className={"presentationGridContainerLayout"}>
				<div>
					<div className={"presentationIContentLayout"}>
						{content()}
					</div>
				</div>
			</div>

		</div>
	)

}
export default Presentation
import TeamMember from "./TeamMember";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";


function Team() {
    const {t} = useTranslation(namespaces.team);
    const elements:Array<any> = t("team",{ns:namespaces.team,returnObjects:true})
    return (

            <div className={"teamGridLayout"}>
                <div className={"teamMainGridLayout"}>
                    {
                        elements.map((e, index) => {
                            const t = "teamMember" + index
                            return(
                                <TeamMember {...e} key={t}/>
                            )
                        })
                    }
                </div>
            </div>

    )
}

export default Team
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

function TeamMember(props:any){
    const {t} = useTranslation(namespaces.socialMedia);

    const nameAndPosition = <div className={"teamTextContainerLayout"}>
        <h3 className={"teamNameLayout teamNameStyle"}>{props["name"]}</h3>
        <p className={"teamPositionLayout teamPositionStyle"}>{props["position"]}</p>
    </div>;

    const instagram = <a href={props["liLink"]} target="_blank" rel="noreferrer" className={"smAnchor"}><img
        className={"teamSocialLILayout teamSocialLIStyle"}
        src={t("socialMedia.5.logo", {ns: namespaces.socialMedia})}
        alt={t("socialMedia.5.alt", {ns: namespaces.socialMedia})}/></a>;

    const linkedin = <a href={props["igLink"]} target="_blank" rel="noreferrer" className={"smAnchor"}><img
        className={"teamSocialIGLayout teamSocialIGStyle"}
        src={t("socialMedia.4.logo", {ns: namespaces.socialMedia})}
        alt={t("socialMedia.4.alt", {ns: namespaces.socialMedia})}/></a>;

    const photo = <div className={"teamPhotoContainerLayout"}>
        <img src={props["photo"]} alt={props["alt"]} className={"teamPhotoLayout teamPhotoStyle"}/>
    </div>;

    return(
            <div key={props.id} className={"teamGridItemLayout"}>
                <div className={"teamMemberLayout"}>
                    {photo}
                    {nameAndPosition}
                    <div className={"teamDescriptionLayout teamDescriptionStyle"}>{props["description"]}</div>
                    <div className={"teamSocialLayout"}>
                        {instagram}
                        {linkedin}
                    </div>
                </div>
            </div>

	)
}

export default TeamMember
import SocialMediaIcons from "../socialMedia/SocialMediaIcons";
import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {generateHelpArray} from "../../utils";
import {logout, useGetLoginInfo} from "@elrondnetwork/dapp-core";

const DropdownNav = () => {
	const {t} = useTranslation(namespaces.navbar);
	const elements = generateHelpArray(Number(t("size", {ns: namespaces.navbar})));
	const [show, setShow] = useState(false)
	const { isLoggedIn } = useGetLoginInfo();


	const logOut = () => {
		logout(`${window.location.origin}/unlock`)
		localStorage.clear();
	};

	const connect =
		<button className={"navbarElementLayout navbarButtonStyle"} onClick={() => window.location.replace("/unlock")}>
			<div className={"dropDownButtonLayout"}>
				<div>
					{t("connect", {ns: namespaces.navbar})}
				</div>
				<div>
					<img src={"flash.png"}
					     alt={""}
					     style={{width: "18px", height: "18px"}}/>
				</div>
			</div>
		</button>

	const disconnect =
		<button className={"navbarElementLayout navbarButtonStyle"}
		        onClick={logOut}>
			<div className={"inlineNavBarConnectTextLayout"}>
				{t("disconnect", {ns: namespaces.navbar})}
				<div className={"inlineNavbarConnectLogoLayout"}>
					<img src={t("dIcon", {ns: namespaces.navbar})}
					     alt={""}
					     className={"inlineNavbarConnectLogoStyle"}/>
				</div>

			</div>
		</button>

	const social =
		<div className={"navbarElementLayout navbarHeadLayout"}>
			<SocialMediaIcons/>
		</div>;

	const arrow =
		<div className={"dropdown-icon"}
		     onClick={() => setShow(currentShow => !currentShow)}>
			<div className="dropDownNavBounceAnimation">
				<img src={"arrows/arrowDownNavbar.png"} style={{width: "18px", height: "18px"}}/>
			</div>
		</div>;

	const navElements = <div className={"navbarElementsLayout"}>
		{elements.map((e: any, index) => {
			const k = "navElements" + index
			if(index === elements.length - 1){
				return (<></>)
			}else return (
				<a key={k} href={"#" + t("navbarElements." + index + ".link", {ns: namespaces.navbar})}
				   className={"navbarElementLayout"}>
					{t("navbarElements." + index + ".name", {ns: namespaces.navbar})}
				</a>
			)
		})}
		<a key={"whitepaper"} href={"https://github.com/Galudas/Rising-Fauna-Whitepaper/blob/main/whitepaper.pdf"}
		   className={`navbarElementLayout navbarElementStyle navbarWhitePaperStyle navbarWhitePaperAnimStyle`}>
			Whitepaper
		</a>
	</div>;


	return (
		<div id={"navbar"}>
			<div className={"dropDownNavbarLayout"}>
				{isLoggedIn? disconnect : connect}
			</div>
			<div className={"navbarLayout"}>
				{social}
				{arrow}
				{show ?
					<div>
						{navElements}
					</div> : <Fragment/>}
			</div>
		</div>
	)
}

export default DropdownNav
import {useParams} from "react-router-dom";
import SlideShow, {SlideModel} from "../slideShow/SlideShow";
import {namespaces} from "../../i18n/i18n.constants";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

export default function CompleteMint(){
    const {t} = useTranslation(namespaces.mint);
    const params = new URLSearchParams(window.location.search);
    const data = {
        status: params.get("status"),
        txHash: params.get("txHash")
    }
    console.log(data)
    const [error, setError] = useState(false);
    const images:Array<SlideModel> = t("images",{ns:namespaces.mint, returnObjects: true})
    useEffect(() => {
        if(data.status === 'success'){
            setError(false);
        }else{
            setError(true);
        }
    },[])
    const goBack = () =>{
        window.location.replace("/")
    }
    return(
        <div>
            <div className={"completeMintMainLayout"}>
                <div className={error ? "completeErrorMintLayout" : "completeSuccessMintLayout"}>
                    {
                        error ?
                            <div>
                                <img className={"completeMintImageLayout"} src={t("completeMint.errorIcon", {ns:namespaces.mint})}/>
                                <div className={"completeMintTextLayout completeMintTextStyle"}>{t("completeMint.error", {ns:namespaces.mint})}</div>
                                <div className={"completeMintTextLayout completeMintTextStyle"}>{t("completeMint.transaction", {ns:namespaces.mint})}<a className = {"completeMintTransactionStyle"} href = {"transactions/" + data.txHash} target="_blank" rel="noreferrer"> here</a></div>
                            </div>
                            :
                            <div>
                                <img className={"completeMintImageLayout"} src={t("completeMint.successIcon", {ns:namespaces.mint})}/>
                                <div className={"completeMintTextLayout completeMintTextStyle"}>{t("completeMint.success", {ns:namespaces.mint})}</div>
                                <div className={"completeMintTextLayout completeMintTextStyle"}>{t("completeMint.view", {ns:namespaces.mint})}</div>
                                <div className={"completeMintTextLayout completeMintTextStyle"}>{t("completeMint.transaction", {ns:namespaces.mint})}<a className = {"completeMintTransactionStyle"} href = {"transactions/" + data.txHash} target="_blank" rel="noreferrer"> here</a></div>
                            </div>
                    }
                    <div onClick={goBack} className={"btn btn-primary completedMintBackButtonLayout"}>Back to main page</div>
                </div>
            </div>
        </div>
    )

}
import {useState, useEffect} from 'react';
import throttle from 'lodash.throttle';

const getDeviceConfig = (width: number) => {
    if (width <= 990) {
        return 'phone';
    } else if (width > 990) {
        return 'desktop';
    }
};


const getDeviceConfigLegendary = (width: number) => {
    if (width < 1200) {
        return 'phone';
    } else if (width >= 1200) {
        return 'desktop';
    }
};

export const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

    useEffect(() => {
        const calcInnerWidth = throttle(function () {
            setBrkPnt(getDeviceConfig(window.innerWidth))
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
}

export const useBreakpointLegendary = () => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceConfigLegendary(window.innerWidth));

    useEffect(() => {
        const calcInnerWidth = throttle(function () {
            setBrkPnt(getDeviceConfig(window.innerWidth))
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return brkPnt;
}


export default useBreakpoint;
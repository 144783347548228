export type Contact = {
	logo: string,
	link: string
}

type Props = {
	name: string,
	subName?: string,
	image: string,
	contact: Array<Contact>
}

export const Advisor = (props: Props) => {
	return (
		<div className={"advisorLayout"}>
			<div className={"advisorImageLayout"}>
				<img className={"advisorImageStyle"} src={props.image} alt={""}/>
			</div>
			<div className={"advisorContentLayout"}>
				<div className={"advisorContentStyle"}>
					<div className={"advisorTextStyle"}>{props.name}</div>
					{props.subName && <div className={"advisorTextSubNameLayout"}>{props.subName}</div>}
					<div className={"advisorContactLayout"}>
						{props.contact.map((c, key) => {
							const k = "contact" + key
							return (
								<a key={k} href={c.link} target="_blank" rel="noreferrer">
									<img className={"advisorLogoStyle"} src={c.logo} alt={""}/>
								</a>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
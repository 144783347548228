import {useHistory} from "react-router-dom";
import { DappUI } from "@elrondnetwork/dapp-core";

function WalletConnect() {
    const history = useHistory();
    const goBack = () =>{
        history.push("/unlock")
    }
    return(
        <div className={"dappContainer"}>
            <div className={"dappText"}>
                <DappUI.WalletConnectLoginContainer
                    callbackRoute="/"
                    loginButtonText="Login with Maiar"
                    title = 'Maiar Login'
                    logoutRoute = '/'
                    lead = 'Scan the QR code using Maiar'
                />
                <div onClick={goBack} className={"btn btn-primary dappButton"}>Back</div>
            </div>
        </div>

    )
}
export default WalletConnect

import { DappUI } from "@elrondnetwork/dapp-core";
import {useHistory} from "react-router-dom";

function Ledger() {
    const history = useHistory();
    const goBack = () =>{
        history.push("/unlock")
    }
    return(
        <div className={"dappContainer"}>
            <div className={"dappText"}>
                <DappUI.LedgerLoginContainer
                    callbackRoute="/"
                />
                <div onClick={goBack} className={"btn btn-primary dappButton"}>Back</div>
            </div>
        </div>

    )
}

export default Ledger
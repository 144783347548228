import SocialMediaIcons from "../socialMedia/SocialMediaIcons";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {logout, useGetLoginInfo} from "@elrondnetwork/dapp-core";

export const InlineNav = () => {
	const {t} = useTranslation(namespaces.navbar);
	const { isLoggedIn } = useGetLoginInfo();

	const logOut = () => {
		console.log("ENtedeeeer")
		logout(`${window.location.origin}/`)
		localStorage.clear();
	};

	const elements: Array<any> = t("navbarElements", {ns: namespaces.navbar, returnObjects: true})

	const connect =
		<button className={"navbarElementLayout navbarButtonStyle"}
		        onClick={() => window.location.replace("/unlock")}>
			<div className={"inlineNavBarConnectTextLayout"}>
				{t("connect", {ns: namespaces.navbar})}
				<div className={"inlineNavbarConnectLogoLayout"}>
					<img src={t("cIcon", {ns: namespaces.navbar})}
					     alt={""}
					     className={"inlineNavbarConnectLogoStyle"}/>
				</div>

			</div>
		</button>


	const disconnect =
		<button className={"navbarElementLayout navbarButtonStyle"}
		        onClick={logOut}>
			<div className={"inlineNavBarConnectTextLayout"}>
				{t("disconnect", {ns: namespaces.navbar})}
				<div className={"inlineNavbarConnectLogoLayout"}>
					<img src={t("dIcon", {ns: namespaces.navbar})}
					     alt={""}
					     className={"inlineNavbarConnectLogoStyle"}/>
				</div>

			</div>
		</button>

	const navbarElement = (e: any, key: string) =>
		!t(e["isWhitePaper"], {ns: namespaces.navbar}) ? <a key={key} href={"#" + e["link"]}
		   className={"navbarElementLayout navbarElementStyle"}>
			{t(e["name"], {ns: namespaces.navbar})}
		</a> :  <a key={key} href={e["link"]}
		           className={`navbarElementLayout navbarElementStyle ${t(e["styleClass"], {ns: namespaces.navbar})}`}>
			{t(e["name"], {ns: namespaces.navbar})}
		</a>

	const socialMedia =
		<div className={"navbarElementLayout navbarHeadLayout"}>
			<SocialMediaIcons/>
		</div>

	return (
		<div id="navbar" className={"navbarLayout"}>
			{socialMedia}
			<div className={"navbarElementsLayout"}>
				{
					elements.map((e, index) => {
						const k = "navbarElement" + index
						return (
							navbarElement(e, k)
						)
					})
				}
				{isLoggedIn ? disconnect : connect}
			</div>
		</div>
	)


}

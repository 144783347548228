import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
import {useHistory} from "react-router-dom";
import decimalToHexa from "../../utils";
import {sendTransactions} from "@elrondnetwork/dapp-core";
import {contractAddress} from "../../config";
import {useGetLoginInfo } from '@elrondnetwork/dapp-core';

export const Counter = () => {
	const history = useHistory();
	const [value, setValue] = useState(1);
	const [error,showError] = useState(false);
	const {t} = useTranslation(namespaces.mint);
	const errorText = t("counter.error",namespaces.mint);
	const { isLoggedIn } = useGetLoginInfo();

	// @ts-ignore
	const handleOnChange = (event : ChangeEvent<HTMLInputElement>) => {

		if(((Number(event.target.value) <= 5 && Number(event.target.value) > 0 ))){
			showError(false);
			setValue(Number(event.target.value));
		}else{
			showError(true);
			setValue(1)
		}
	}

	React.useEffect(() => {
		if (!isLoggedIn) {
			window.location.href = "/unlock";
		}
	}, [isLoggedIn]);




	const handleClick = async () => {
		if(((value <= 5 && value > 0))){
			showError(false);
					await sendTransactions({
						transactions:[
							{
								receiver: contractAddress,
								value: (900000000000000000 * value).toString(),
								data: "mint@" + decimalToHexa(value.toString()),
							}
						],
						callbackRoute:"/",
						transactionsDisplayInfo: {
							processingMessage: 'Processing Mint transaction',
							errorMessage: 'An error has occurred',
							successMessage: 'Mint transaction successful'
						},
						redirectAfterSign: true
					})
				}

		else {
			showError(true);
			setValue(1)
		}
	}
	const add = () => {
		setValue(value < 5 ? value + 1 : 5);
	}
	const substract = () => {
		setValue(value <= 1 ? 1 : value - 1);
	}


	const onError = error ? <div className={"modalCounterErrorTextLayout"}>
			<p className={"modalCounterErrorTextStyle"}>{errorText}</p>
		</div> : <></>
	return (
		<div>
			 <div id="openModal" className="modalDialogLayout">
				<div className={"modalCounterLayout animate__animated animate__bounceIn"}>
					<div className={"modalCounterHeadlinePositionStyle"}>
						<img width={300} src={t("counter.logo", {ns: namespaces.mint})}/>
					</div>
					<div className={"modalCounterInputPositionLayout"}>
						<div className={"modelCounterInputLayout"}>
							<button className={"modelCounterMinusLayout"} onClick={substract}>
								<img src={"minus.png"} alt={""}/>
							</button>
							<input value = {value} className={error ? "modalCounterErrorStyle" : ""} id="counterInputId" type="number"
								  	min = {0} max = {5} onChange={(event) => {
								handleOnChange(event)
							}} readOnly/>
							<button className={"modelCounterPlusLayout"} onClick={add}>
								<img src={"plus.png"} alt={""}/>
							</button>
						</div>

					</div>
					{
						onError
					}
					<div className = {"counterMainPriceLayout"}>
						<div className = {"counterPriceTextLayout counterPriceTextStyle"}>{t("counter.price", {ns: namespaces.mint})}</div>
						<div className = {"counterPriceLayout counterPriceStyle"}>{(value*0.9).toFixed(2) + " EGLD"}</div>
					</div>
					<div className={"modalCounterSubmitPositionLayout"}>
						<button onClick={() => handleClick()} className={"modalCounterSubmitStyle"}>
							Mint {value} {value > 1 ? "NFT's" : "NFT"}
						</button>
					</div>
					<div className={"modalCounterSubmitPositionLayout"}>
						<button onClick={() => window.location.replace("/")} className={"modalButtonBackStyle"}>
							{t("back", {ns: namespaces.mint})}
						</button>
					</div>


					<div className={"counterAsterixMessageStyle counterAsterixMessageLayout"}>
						{t("counter.asterix", {ns: namespaces.mint})}
					</div>

				</div>

			</div>


		</div>
	);
}
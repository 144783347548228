type Props = {
	data: any
}

const GridElement = (props: Props) => {
	const e = props.data;

	const image =
		<div className={"gridElementImageLayout"}>
			<img src={e["photo"]} className={"gridElementImageStyle"} alt={e["alt"]}/>
		</div>

	const title =
		<div className={"gridElementTitleLayout"}>
			<h1 className={"gridElementTitleStyle"}>{e["name"]}</h1>
		</div>

	const element = (e: any, key: string) =>
		<li key={key} className={"description"}>
			{e}
		</li>

	return (
		<div>
			<div className={"gridElementLayout"}>
				{title}
				{image}
				<div>
					<ul className={"gridElementChildLayout"}>
						{(e["description"] as Array<any>).map((d, index) => {
							const e = "element" + index
							return (
								element(d, e)
							)
						})}
					</ul>
				</div>
			</div>
		</div>

	)
}

export default GridElement
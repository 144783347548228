import useBreakpoint from "../../genericBreakpoint";

import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

const About = () => {
	const {t} = useTranslation(namespaces.about)
	const breakpoint = useBreakpoint();

	const firstImage =
		<div className={"aboutImageLayout"}>
			<img className={"aboutImageStyle"}
			     src={t("first.photo", {ns: namespaces.about})}
			     alt={t("first.alt", {ns: namespaces.about})}/>
		</div>

	const fistText =
		<div className={"aboutTextLayout"}>
			<p className={"aboutQuestionStyle"}>{t("first.question", {ns: namespaces.about})}</p>
			<p className={"aboutTextStyle"}>
				{t("first.text", {ns: namespaces.about})}
			</p>
		</div>

	const secondText =
		<div className={"aboutTextLayout"}>
			<p className={"aboutQuestionStyle"}>{t("second.question", {ns: namespaces.about})}</p>
			<p className={"aboutTextStyle"}>
				{t("second.text", {ns: namespaces.about})}
			</p>
		</div>;

	const thirdPhoto =
		<div className={"aboutImageLayout"}>
			<img className={"aboutImageStyle"}
			     src={t("third.photo", {ns: namespaces.about})}
			     alt={t("third.alt", {ns: namespaces.about})}/>
		</div>;

	const thirdText =
		<div className={"aboutTextLayout"}>
			<p className={"aboutQuestionStyle"}>{t("third.question", {ns: namespaces.about})}</p>
			<p className={"aboutTextStyle"}>
				{t("third.text", {ns: namespaces.about})}
			</p>
		</div>;

	switch (breakpoint) {
		case ("desktop"):
			return (
				<div className={"aboutLayout"}>
					<div className={"aboutGridElementLayout"}>
						{firstImage}
						{fistText}
					</div>

					<div className={"aboutGridElementLayout"}>
						<div/>
						{secondText}
					</div>

					<div className={"aboutGridElementLayout"}>
						{thirdPhoto}
						{thirdText}
					</div>
				</div>
			)
		case ("phone"):
			return (
				<div className={"aboutLayout"}>
					<div className={"aboutGridElementLayout"}>
						{fistText}
					</div>

					<div className={"aboutGridElementLayout"}>
						{firstImage}
						{secondText}
					</div>

					<div className={"aboutGridElementLayout"}>
						{thirdPhoto}
						{thirdText}
					</div>
				</div>
			)
		default:
			return (
				<></>
			)

	}
}


export default About
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

function FooterMain (){
    const {t} = useTranslation(namespaces.footer)

    return(
        <div className={"footerMainLayout"}>
            <h2 className={"footerMainTitleStyle"}> {t("main.firstTitle",{ns: namespaces.footer})} {t("main.secondTitle",{ns: namespaces.footer})}</h2>
            <p className={"footerMainTextStyle"}>{t("main.text",{ns: namespaces.footer})}</p>
        </div>
    )
}
export default FooterMain
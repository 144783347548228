import FooterMain from "./FooterMain";
import FooterSocial from "./FooterSocial";
import FooterBuy from "./FooterBuy";
import FooterContact from "./FooterContact";

const Footer = () => {
	const footerLogo = (
		<div className={"footerLogoLayout"}>
			<img style={{width: "200px", margin: "auto"}} src={"logo-original.png"} alt={""}/>
		</div>
	)

	const footerCopyRight = (
		<div className={"footerCopyRight"}>Copyright &copy; 2022 Rising Fauna</div>
	)

	return (
		<div className={"footerStyle"}>
			<div className={"footerLayout"}>
				<div className={"footerGridLayout"}>
					<FooterMain/>
					<FooterSocial/>
					<FooterBuy/>
					<FooterContact/>
				</div>
				{footerLogo}
                {footerCopyRight}
			</div>
		</div>
	)
}

export default Footer
export {}

export const carouselElement = (e: any, key: number) => {
	return (
		<div key={key}>
			<img src={e["photo"]} alt={e["alt"]} className={"carouselImageImg"}/>
			<div className={"carouselBlockTitleLayout"}>
				<h4 className={"carouseBlockTitleH4"}>{e.title}</h4>
			</div>
			<ul className={"carouselImageLayout"}>
				{(e["description"] as Array<any>).map((e, index) => {
					return (
						<li className={"carouselContentLi"} key={index}>{e}</li>)
				})}
			</ul>

		</div>
	);
}


import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

const SocialMediaIcons = () => {
	const {t} = useTranslation(namespaces.socialMedia);

	const instagram =
		<a href={t("socialMedia.0.link", {ns: namespaces.socialMedia})}
		   target="_blank"
		   rel="noreferrer"
		   className={"navbarElementLayout socialMediaIconsLinkLayout"}>
			<img className={"socialMediaIconImageStyle"}
			     src={t("socialMedia.0.logo", {ns: namespaces.socialMedia})}
			     alt={t("socialMedia.0.alt", {ns: namespaces.socialMedia})}/>
		</a>

	const twitter =
		<a href={t("socialMedia.3.link", {ns: namespaces.socialMedia})}
		   target="_blank" rel="noreferrer"
		   className={"navbarElementLayout socialMediaIconsLinkLayout"}>
			<img className={"socialMediaIconImageStyle socialMediaIconsTwitterStyle"}
			     src={t("socialMedia.3.logo", {ns: namespaces.socialMedia})}
			     alt={t("socialMedia.3.alt", {ns: namespaces.socialMedia})}/>
		</a>

	const telegram =
		<a href={t("socialMedia.2.link", {ns: namespaces.socialMedia})}
		   target="_blank"
		   rel="noreferrer"
		   className={"navbarElementLayout socialMediaIconsLinkLayout"}>
			<img className={"socialMediaIconImageStyle"}
			     src={t("socialMedia.2.logo", {ns: namespaces.socialMedia})}
			     alt={t("socialMedia.2.alt", {ns: namespaces.socialMedia})}/>
		</a>

	const discord =
		<a href={t("socialMedia.1.link", {ns: namespaces.socialMedia})}
		   target="_blank"
		   rel="noreferrer"
		   className={"navbarElementLayout socialMediaIconsLinkLayout"}>
			<img className={"socialMediaIconImageStyle socialMediaIconsDiscordStyle"}
			     src={t("socialMedia.1.logo", {ns: namespaces.socialMedia})}
			     alt={t("socialMedia.1.alt", {ns: namespaces.socialMedia})}/>
		</a>

	return (
		<div className={"socialMediaIconsLayout"}>
			{instagram}
			{twitter}
			{telegram}
			{discord}
		</div>
	)
}

export default SocialMediaIcons;
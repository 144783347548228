import "../../style/components/loading.sass"

export const Loading = () => {

	return(
		<div id="load">
			<div className="logo">
				<img src={"logo-original.png"} className="image-center-loading" alt={""}/>
			</div>
		</div>
	)
}